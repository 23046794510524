<template>
    <div class="h-8 w-full flex flex-row justify-between items-center" @click="go()">

        <div class="h-full w-2/5 flex flex-col justify-center items-center">
            <div class="h-5 w-full rounded-md  flex flex-col justify-center items-center" :style="'background-color:'+color+'33; color: '+color">
                <span class="text-xxs">{{ name }}</span>
            </div>
        </div>

        <div class="h-full w-1/5 flex flex-col justify-center items-center">
            <span class="text-white font-semibold text-xxs"> {{ sector.oportunity | numberFormat(0) }}</span>
        </div>

        <div class="h-full w-2/5 flex flex-col justify-center items-center">
            <span class="text-font-gray text-xxs">{{ sector.sales | reduceBigNumbers() }} €</span>
        </div>

        

        
        

    </div>
</template>

<script>
import { actions,state } from '@/store'

    export default {
        name: "Sector",
        props: ['sector', 'name','color'],
        methods:{
            go(){
                actions.setPipelineMobile()
                this.$router.push( {name:'Opportunities'} )
            }
        }
    }
</script>